<template>
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3327 8.79964C13.3327 8.66703 13.28 8.53986 13.1862 8.44609C13.0925 8.35232 12.9653 8.29964 12.8327 8.29964H10.8327C10.7001 8.29964 10.5729 8.35232 10.4791 8.44609C10.3854 8.53986 10.3327 8.66703 10.3327 8.79964V13.2996H9.33268V2.46631C9.33268 1.98098 9.33135 1.66631 9.30068 1.43564C9.27135 1.21898 9.22402 1.15098 9.18602 1.11298C9.14802 1.07498 9.08002 1.02764 8.86335 0.998309C8.63201 0.967642 8.31802 0.966309 7.83268 0.966309C7.34735 0.966309 7.03268 0.967642 6.80202 0.998309C6.58535 1.02764 6.51735 1.07498 6.47935 1.11298C6.44135 1.15098 6.39402 1.21898 6.36468 1.43564C6.33402 1.66698 6.33268 1.98098 6.33268 2.46631V13.2996H5.33268V5.46631C5.33268 5.3337 5.28 5.20652 5.18624 5.11276C5.09247 5.01899 4.96529 4.96631 4.83268 4.96631H2.83268C2.70007 4.96631 2.5729 5.01899 2.47913 5.11276C2.38536 5.20652 2.33268 5.3337 2.33268 5.46631V13.2996H1.16602C1.03341 13.2996 0.90623 13.3523 0.812462 13.4461C0.718694 13.5399 0.666016 13.667 0.666016 13.7996C0.666016 13.9323 0.718694 14.0594 0.812462 14.1532C0.90623 14.247 1.03341 14.2996 1.16602 14.2996H14.4993C14.632 14.2996 14.7591 14.247 14.8529 14.1532C14.9467 14.0594 14.9993 13.9323 14.9993 13.7996C14.9993 13.667 14.9467 13.5399 14.8529 13.4461C14.7591 13.3523 14.632 13.2996 14.4993 13.2996H13.3327V8.79964Z"
      fill="#55575D"
    />
  </svg>
</template>
<script>
export default {
  name: "IconAnalytics",
};
</script>
<style scoped lang="scss"></style>
